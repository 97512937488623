import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Button as AntdButton,
  Breadcrumb,
  Switch,
  Tag,
  Spin,
} from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import moment from 'moment';
import MultipleDatePicker from '../../../component/MultipleDatePicker/MultipleDatePicker';
import { Page, Div } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { convertTime } from '../../../helper/object';
import { Project } from '../../../helper/type';
import { setProject as setMappedProjects } from '../../../store/hirer.slice';
import { useJobService } from '../../../service/job.service';
import { RootState } from '../../../store/store';
import { openModal, setFlyoutOpen } from '../../../store/app.slice';
import { countries, malaysiaStates } from '../../../../constant/region';

import './Form.scss';

const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const FormPage = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const history = useHistory();
  const jobService = useJobService();

  const [form] = Form.useForm();

  const [isConsecutive, setIsConsecutive] = useState(false);
  const [isTentative, setIsTentative] = useState(false);
  const [perpetual, setPerpetual] = useState(true);
  const [usageDurationPeriod, setUsageDurationPeriod] = useState('month');
  const [paymentTermPeriod, setPaymentTermPeriod] = useState('month');

  const { projectId } = useParams<{ projectId: string }>();
  const { authHirer } = useSelector((state: RootState) => state.user);
  const { mappedProjects } = useSelector((state: RootState) => state.hirer);
  const [project, setProject] = useState<Project>();
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  useEffect(() => {
    if (projectId) {
      (async () => {
        setFormLoading(true);

        if (!mappedProjects[projectId]) {
          const result = await jobService.getProject(projectId);
          dispatch(setMappedProjects(result));
        } else {
          setProject(mappedProjects[projectId]);
        }

        setFormLoading(false);
      })();
    }
  }, []);

  useEffect(() => {
    setProject(mappedProjects[projectId]);
  }, [mappedProjects]);

  useEffect(() => {
    if (projectId && project) {
      form.setFieldsValue({
        ...project,
        isConsecutiveDate: !project.isNotConsecutiveDate,
        closingDate: moment(project.closingDate),
        ...(project.shootDate && {
          shootDate: [
            moment(project.shootDate.from),
            moment(project.shootDate.to),
          ],
        }),
        ...(project.shootDates && {
          shootDates: project.shootDates.map((date) => moment(date)),
        }),
        usageDuration: {
          perpetual: project.usageDuration.perpetual,
          ...(project.usageDuration.duration && {
            duration: project.usageDuration.duration.split(' ')[0],
            period: project.usageDuration.duration.split(' ')[1],
          }),
        },
      });
      setIsTentative(project.isTentative ?? false);
      setIsConsecutive(!project.isNotConsecutiveDate);
      setPerpetual(project.usageDuration.perpetual);
    }
  }, [project]);

  const getProjectValues = ({
    closingDate,
    shootDate,
    shootDates,
    isConsecutiveDate,
    usageDuration,
    ...values
  }: any) => {
    return {
      ...values,
      hirerId: authHirer.hirerId,
      closingDate: convertTime(closingDate),
      ...(shootDate &&
        isConsecutiveDate && {
          shootDate: {
            from: convertTime(shootDate[0]),
            to: convertTime(shootDate[1]),
          },
        }),
      ...(shootDates &&
        !isConsecutiveDate && {
          shootDates: shootDates
            .map((d: any) => convertTime(d))
            .sort((a: string, b: string) => a.localeCompare(b)),
        }),
      isNotConsecutiveDate: !isConsecutiveDate,
      usageDuration: {
        perpetual: usageDuration.perpetual,
        ...(usageDuration.duration && {
          duration: `${usageDuration.duration} ${usageDuration.period}`,
        }),
      },
    };
  };

  const handleFormSubmitWithoutLogin = async () => {
    const values = await form.validateFields();
    const savedProject = getProjectValues(values);
    history.push('/hirer/project/{new-project}/role', { savedProject });
  };

  const handleFormSubmit = async (isAddAnotherRole: boolean) => {
    const values = await form.validateFields();
    const projectValues = getProjectValues(values);

    let savedProject: Project;
    if (projectId) {
      savedProject = await jobService.updateProject(projectValues, projectId);
      savedProject.id = projectId;
    } else {
      savedProject = await jobService.createProject(projectValues);
    }

    dispatch(setMappedProjects(savedProject));

    history.push(
      isAddAnotherRole
        ? `/hirer/project/${savedProject.id}/role`
        : '/hirer/home'
    );
  };

  const onDelete = async () => {
    dispatch(
      openModal({
        modal: 'delete-project',
        config: {
          context: { project },
        },
      })
    );
  };

  const renderShootingDatePicker = (isConsecutive: boolean) => {
    const label = (
      <>
        <span>Shooting Date</span>
        {isTentative ? (
          <Tag color='#faad14' className='tentative-tag'>
            TENTATIVE
          </Tag>
        ) : null}
      </>
    );
    const rules = [
      {
        required: true,
        message: 'Please select shoot date(s) for this project.',
      },
    ];
    if (isConsecutive) {
      return (
        <Form.Item name='shootDate' label={label} rules={rules}>
          <RangePicker
            popupClassName='project-form-shoot-date'
            format={'DD MMM yyyy'}
            disabledDate={(date) => date && date < moment().endOf('day')}
          />
        </Form.Item>
      );
    }
    return (
      <Form.Item name='shootDates' label={label} rules={rules}>
        <MultipleDatePicker
          name='shootDates'
          placeholder='Choose shooting date'
          dates={project?.shootDates}
          form={form}
          disabledDate={(date) => date && date < moment().endOf('day')}
        />
      </Form.Item>
    );
  };

  const renderPeriodSelect = ({
    name,
    initialValue,
    onChange,
  }: {
    name: NamePath | undefined;
    initialValue: any;
    onChange: (value: string) => void;
  }) => {
    return (
      <Form.Item name={name} initialValue={initialValue} noStyle>
        <Select
          onChange={onChange}
          options={['day', 'week', 'month', 'year'].map((period) => ({
            label: period,
            value: period,
          }))}
        />
      </Form.Item>
    );
  };

  return (
    <Page className='page-hirer-form'>
      {authHirer.hirerId && (
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to='/hirer/home'>All Projects</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{project?.title || 'Project'}</Breadcrumb.Item>
        </Breadcrumb>
      )}

      <Spin spinning={formLoading}>
        <Form form={form} layout='vertical'>
          <Title level={2}>{projectId ? 'Edit' : 'Create'} Project</Title>

          <Title level={3}>Details</Title>
          <Form.Item
            name='title'
            label='Title'
            rules={[
              {
                required: true,
                message: 'Please input project title',
              },
            ]}
          >
            <Input placeholder='eg. Commercial for Property' />
          </Form.Item>
          <Form.Item
            name='description'
            label='Description'
            rules={[
              {
                required: true,
                message: 'Please provide description about this project.',
              },
            ]}
          >
            <Input.TextArea
              placeholder='eg. Promote product and increase brand awareness'
              showCount
              autoSize={{
                minRows: 3,
                maxRows: 4,
              }}
            />
          </Form.Item>
          <Form.Item
            name='media'
            label='Media'
            rules={[
              {
                required: true,
                min: 1,
                type: 'array',
                message: 'Please choose at least 1',
              },
            ]}
          >
            <Select
              mode='multiple'
              placeholder='You can select more than one'
              dropdownRender={(menu) => <Div>{menu}</Div>}
              options={[
                {
                  label: 'Media Type',
                  options: [
                    { label: 'Commercial/Ads', value: 'Commercial/Ads' },
                    { label: 'Videoshoot', value: 'Videoshoot' },
                    { label: 'Photoshoot', value: 'Photoshoot' },
                    { label: 'TikTok Series', value: 'TikTok Series' },
                    { label: 'Drama', value: 'Drama' },
                    { label: 'Movie', value: 'Movie' },
                    { label: 'Short Film', value: 'Short Film' },
                    { label: 'Student Project', value: 'Student Project' },
                    { label: 'Corporate Video', value: 'Corporate Video' },
                    { label: 'Internal Video', value: 'Internal Video' },
                    { label: 'Others', value: 'Others' },
                  ],
                },
                {
                  label: 'Media Usage',
                  options: [
                    { label: 'Digital', value: 'Digital' },
                    { label: 'TVC', value: 'TVC' },
                    { label: 'DOOH', value: 'DOOH' },
                    { label: 'OOH', value: 'OOH' },
                    { label: 'Print Shoot', value: 'Print Shoot' },
                    {
                      label: 'Point of Sales Material',
                      value: 'Point of Sales Material',
                    },
                    { label: 'Internal Usage', value: 'Internal Usage' },
                  ],
                },
              ]}
            />
          </Form.Item>
          <Form.Item name='shootLocation' label='Shoot Location'>
            <Input placeholder='eg. Shah Alam / Kuala Lumpur' />
          </Form.Item>
          <Form.Item name='state' label='State'>
            <Select
              placeholder='W.P Kuala Lumpur / Selangor'
              dropdownRender={(menu) => <Div>{menu}</Div>}
            >
              {[
                malaysiaStates[0],
                'W.P Kuala Lumpur / Selangor',
                ...malaysiaStates.slice(1),
              ]
                .filter((s) => s !== 'W.P Kuala Lumpur' && s !== 'Selangor')
                .map((s) => (
                  <Option key={s} value={s}>
                    {s}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name='contractual'
            label='Contractual'
            valuePropName='checked'
            initialValue={false}
          >
            <Switch checkedChildren='YES' unCheckedChildren='NO' />
          </Form.Item>

          <Div flex>
            <Form.Item
              style={{ flex: '1 1 0' }}
              name='isTentative'
              label='Tentative Date'
              valuePropName='checked'
              initialValue={isTentative}
            >
              <Switch
                checkedChildren='YES'
                unCheckedChildren='NO'
                onChange={setIsTentative}
              />
            </Form.Item>
            <Form.Item
              style={{ flex: '1 1 0' }}
              name='isConsecutiveDate'
              label='Date Selection'
              valuePropName='checked'
              initialValue={isConsecutive}
            >
              <Switch
                checkedChildren='Date Range'
                unCheckedChildren='Multi-date'
                className='date-selection-switch'
                onChange={setIsConsecutive}
              />
            </Form.Item>
          </Div>
          {renderShootingDatePicker(isConsecutive)}

          <Form.Item
            name='closingDate'
            label='Closing Date'
            rules={[
              {
                required: true,
                message: 'Please input your closing date',
              },
            ]}
          >
            <DatePicker
              disabledDate={(current) =>
                current && current < moment().endOf('day')
              }
              placeholder='Choose project closing date'
              format={'DD MMM yyyy'}
            />
          </Form.Item>

          <Form.Item
            initialValue={perpetual}
            label='Perpetual'
            name={['usageDuration', 'perpetual']}
            valuePropName='checked'
          >
            <Switch
              checkedChildren='YES'
              unCheckedChildren='NO'
              onChange={setPerpetual}
            />
          </Form.Item>

          {!perpetual && (
            <Form.Item
              label='Usage Duration'
              name={['usageDuration', 'duration']}
              rules={[
                {
                  required: !perpetual,
                  message: 'Please input duration',
                },
              ]}
            >
              <InputNumber
                min={1}
                placeholder={`6 ${usageDurationPeriod}`}
                addonAfter={renderPeriodSelect({
                  name: ['usageDuration', 'period'],
                  initialValue: 'month',
                  onChange: (value) => setUsageDurationPeriod(value),
                })}
              />
            </Form.Item>
          )}

          <Form.Item
            name='mediaUsageCountries'
            label='Media Usage Countries'
            rules={[
              {
                required: true,
                min: 1,
                type: 'array',
                message: 'Please choose at least 1',
              },
            ]}
          >
            <Select
              mode='multiple'
              placeholder='e.g. Malaysia'
              dropdownRender={(menu) => <Div>{menu}</Div>}
            >
              {countries.map((m) => (
                <Option key={m} value={m}>
                  {m}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label='Payment Period'
            name={['paymentTerm', 'value']}
            rules={[
              {
                required: true,
                message: 'Please input value',
              },
            ]}
          >
            <InputNumber
              min={1}
              placeholder={`6 ${paymentTermPeriod}`}
              addonAfter={renderPeriodSelect({
                name: ['paymentTerm', 'period'],
                initialValue: 'month',
                onChange: (value) => setPaymentTermPeriod(value),
              })}
            />
          </Form.Item>

          <Form.Item>
            {!authHirer.hirerId ? (
              <AntdButton
                type='primary'
                block
                onClick={handleFormSubmitWithoutLogin}
              >
                Add a Role
              </AntdButton>
            ) : projectId ? (
              <>
                <AntdButton
                  type='primary'
                  block
                  onClick={() => handleFormSubmit(false)}
                >
                  Update project
                </AntdButton>
                <AntdButton
                  danger
                  className='btn-delete'
                  htmlType='button'
                  onClick={onDelete}
                  block
                >
                  Delete
                </AntdButton>
              </>
            ) : (
              <>
                <AntdButton
                  type='primary'
                  block
                  onClick={() => handleFormSubmit(true)}
                >
                  Create Project and Add New Role
                </AntdButton>
                <AntdButton
                  type='default'
                  block
                  onClick={() => handleFormSubmit(false)}
                  style={{ marginTop: '1rem' }}
                >
                  Create Project
                </AntdButton>
              </>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </Page>
  );
};

export default FormPage;
