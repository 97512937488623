import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, Form, Input, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import { Div, Page } from '../../../framework';
import { getEmailProviderUrl } from '../../../helper/getEmailProviderUrl';
import { useResetScroll } from '../../../helper/hook';
import { getMilliSeconds } from '../../../helper/object';
import { HIRER_ROUTES } from '../../../route/constant';
import { useSstAuthService } from '../../../service/auth.sst.service';
import { useHirerService } from '../../../service/hirer.service';
import { setFlyoutOpen } from '../../../store/app.slice';

import './Login.scss';

const { Title, Paragraph } = Typography;

const Login = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const hirerService = useHirerService();
  const sstAuthService = useSstAuthService();
  const [form] = Form.useForm();

  const [loginError, setLoginError] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  const handleSubmitLogin = async (values: { email: string }) => {
    try {
      const { exists, ...userData } = await hirerService.emailExists(
        values.email
      );
      if (!exists) {
        setLoginError('Email does not exists.');
        return;
      }

      await sstAuthService.getLink({
        authType: 'hirer_login',
        email: values.email,
        redirect: HIRER_ROUTES.AUTH,
        linkExpiry: getMilliSeconds({ hours: 24 }),
        data: userData,
      });

      setEmail(values.email);
      setEmailSent(true);
    } catch (error) {
      const e = error as AxiosError;
      setLoginError(e.response?.data?.message);
    }
  };

  const renderLoginForm = () => (
    <>
      <Div pt='xxl'>
        <Title level={2} className='text-center'>
          Login
        </Title>
      </Div>

      <Form form={form} layout='vertical' onFinish={handleSubmitLogin}>
        <Form.Item
          name='email'
          label='Email'
          rules={[
            {
              required: true,
              message: 'Please input your email address',
            },
            {
              type: 'email',
              message: 'This is not a valid email',
            },
          ]}
        >
          <Input
            onInput={(e) => {
              const { value, selectionStart, selectionEnd } = e.currentTarget;
              e.currentTarget.value = value.toLowerCase();
              e.currentTarget.setSelectionRange(selectionStart, selectionEnd); // to preserve cursor position
            }}
          />
        </Form.Item>
        <Div className='text-red'>{loginError}</Div>
        <Form.Item>
          <Button type='primary' htmlType='submit' size='large' block>
            Continue
          </Button>
        </Form.Item>
      </Form>

      <Div pb='xl'>
        <Paragraph className='text-center'>
          New to Heycast.me?{' '}
          <Link to={HIRER_ROUTES.REGISTER}>Create an account</Link>
        </Paragraph>
      </Div>
    </>
  );

  const renderEmailSentFeedback = () => (
    <>
      <Div pt='xxl'>
        <Title level={2} className='text-center'>
          <Div>
            <MailOutlined />
          </Div>
          Check your email!
        </Title>
      </Div>

      <Div className='text-center' pb='xl'>
        <Paragraph>
          We have sent an email to {email}. It has a magic link that will sign
          you in to Heycast.me.
        </Paragraph>

        <Paragraph>P.S: The link will expire in 24 hours.</Paragraph>
      </Div>

      <Button
        type='primary'
        size='large'
        target='_blank'
        href={
          email
            ? getEmailProviderUrl(email, 'Your Login Link is Here')
            : undefined
        }
        block
      >
        Open Email App
      </Button>
    </>
  );

  return (
    <Page className='page-hirer-login'>
      {emailSent ? renderEmailSentFeedback() : renderLoginForm()}
    </Page>
  );
};

export default Login;
