import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Typography, Breadcrumb } from 'antd';
import RatingCommentList from '../../../component/RatingCommentList/RatingCommentList';
import { Div } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { Applicant, Rating } from '../../../helper/type';
import { useRatingService } from '../../../service/rating.service';
import { useJobService } from '../../../service/job.service';
import { RootState } from '../../../store/store';

import './ViewComments.scss';

const { Title } = Typography;

const ViewCommentsPage = () => {
  useResetScroll();
  const ratingService = useRatingService();
  const jobService = useJobService();
  const { talentId, roleId } = useParams<Record<string, string>>();
  const { mappedProjects, mappedApplicants } = useSelector(
    (state: RootState) => state.hirer
  );

  const [currentRating, setCurrentRating] = useState<Rating>();
  const [applicant, setApplicant] = useState<Applicant>();

  const projectId = currentRating?.projectId;

  useEffect(() => {
    (async () => {
      const currentApplicant =
        mappedApplicants[roleId] &&
        mappedApplicants[roleId].applicants.find(
          (appl) => appl.talentId === talentId
        );
      if (!currentApplicant) {
        const appl = await jobService.getApplicant(roleId, talentId);
        setApplicant(appl);
      } else {
        setApplicant(currentApplicant);
      }
    })();
  }, []);

  const fetchRating = async () => {
    const rating = await ratingService.getRating(talentId, roleId, true);
    setCurrentRating(rating);
  };

  return (
    <Div className='page-hirer-view-comments'>
      <Helmet>
        <title>View Comments - Heycast.me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to='/hirer/home'>All Projects</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {projectId && (
            <Link to={`/hirer/project/${projectId}/ratings`}>
              {currentRating && mappedProjects[projectId]?.title}
            </Link>
          )}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {projectId && (
            <Link to={`/hirer/project/${projectId}/ratings`}>View Ratings</Link>
          )}
        </Breadcrumb.Item>
        <Breadcrumb.Item>{applicant?.name}</Breadcrumb.Item>
      </Breadcrumb>

      <Div className='title-container'>
        <Title level={4} className='title'>
          View Comments
        </Title>
      </Div>

      <RatingCommentList
        userType='hirer'
        currentRating={currentRating}
        fetchRating={fetchRating}
      />
    </Div>
  );
};

export default ViewCommentsPage;
