import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Breadcrumb, List } from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { Div, Button } from '../../../framework';
import { useActivityService } from '../../../service/activity.service';
import { Schedule } from '../../../helper/type';
import { HIRER_ROUTES } from '../../../route/constant';
import { setFlyoutOpen, setMenuMode } from '../../../store/app.slice';
import { setHirerSchedules } from '../../../store/hirer.slice';
import { RootState } from '../../../store/store';

import './Schedules.scss';

const SchedulesPage = () => {
  const dispatch = useDispatch();
  const { loadStack } = useSelector((state: RootState) => state.app);
  const { authHirer } = useSelector((state: RootState) => state.user);
  const activityService = useActivityService();
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const isLoading = loadStack.includes(
    `activity/schedules/${authHirer.hirerId}`
  );

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  useEffect(() => {
    (async () => {
      const result: Schedule[] = await activityService.listHirerSchedules(
        authHirer.hirerId
      );
      result.forEach((sch) =>
        dispatch(
          setHirerSchedules({ scheduleId: sch.scheduleId, schedule: sch })
        )
      );
      setSchedules(result);
    })();
  }, []);

  const formatSlotDateRange = (slots: Schedule['slots']) => {
    const dates = Object.keys(slots).sort();
    const dateFormat = 'DD MMM YYYY';
    const start = moment(dates[0]).format(dateFormat);
    const end = moment(dates[dates.length - 1]).format(dateFormat);
    return `${start} - ${end}`;
  };

  return (
    <Div className='page-hirer-schedules'>
      <Helmet>
        <title>Schedules - Heycast.me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to='/hirer/home'>Dashboard</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Schedules</Breadcrumb.Item>
      </Breadcrumb>

      <Div pt='l' className='page-title' flex>
        <Title level={4}>Schedules</Title>
        <Div flex className='button-container'>
          <Button style={{ flex: 'unset' }} to={HIRER_ROUTES.SCHEDULE_CREATE}>
            + New Schedule
          </Button>
        </Div>
      </Div>

      <List
        loading={isLoading}
        dataSource={schedules}
        renderItem={(sch) => (
          <List.Item>
            <Div ph='m' pv='s'>
              <Link to={`/hirer/schedule/edit/${sch.scheduleId}`}>
                {sch.scheduleName ??
                  Object.keys(sch.slots).sort((s1, s2) =>
                    s2.localeCompare(s1)
                  )[0]}
              </Link>
              <br />
              <span style={{ fontSize: 'small' }}>
                {formatSlotDateRange(sch.slots)}
              </span>
            </Div>
          </List.Item>
        )}
      />
    </Div>
  );
};

export default SchedulesPage;
