import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Typography, Breadcrumb, Empty } from 'antd';
import HirerViewRatingCard from '../../../component/Card/HirerViewRatingCard/HirerViewRatingCard';
import SkeletonHirerViewRatingCard from '../../../component/Skeleton/HirerViewRatingCard';
import { Div } from '../../../framework';
import { RootState } from '../../../store/store';
import { useResetScroll } from '../../../helper/hook';
import { useRatingService } from '../../../service/rating.service';
import { useJobService } from '../../../service/job.service';
import {
  setProject as setGlobalProject,
  setRoles as setGlobalRoles,
  setProjectRatings,
} from '../../../store/hirer.slice';

import './ViewRatings.scss';

const { Title } = Typography;

const ViewRatingsPage = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const ratingService = useRatingService();
  const jobService = useJobService();
  const { projectId } = useParams<Record<string, string>>();
  const { mappedProjects, mappedRoles, mappedProjectRatings } = useSelector(
    (state: RootState) => state.hirer
  );

  useEffect(() => {
    (async () => {
      const ratings = await ratingService.listRatingsByProject(projectId);
      dispatch(setProjectRatings({ projectId, ratings }));

      if (!mappedProjects[projectId]) {
        const proj = await jobService.getProject(projectId);
        dispatch(setGlobalProject(proj));
      }

      if (!mappedRoles[projectId]) {
        const roles = await jobService.getRolesByProject(projectId);
        dispatch(setGlobalRoles({ projectId, roles }));
      }
    })();
  }, []);

  return (
    <Div className='page-view-ratings'>
      <Helmet>
        <title>View Ratings - Heycast.me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to='/hirer/home'>All Projects</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{mappedProjects[projectId]?.title}</Breadcrumb.Item>
        <Breadcrumb.Item>View Ratings</Breadcrumb.Item>
      </Breadcrumb>

      <Div className='title-container'>
        <Title level={4}>View Ratings</Title>
      </Div>

      <Div mb='xl'>
        {(!mappedProjectRatings[projectId] ||
          mappedProjectRatings[projectId].length === 0) && (
          <Div className='zero-state'>
            <Empty description='No rating submitted on this project' />
          </Div>
        )}

        {mappedProjectRatings[projectId]?.map((rating) => {
          const project = mappedProjects[projectId];
          const role =
            mappedRoles[projectId] && mappedRoles[projectId][rating.roleId];

          if (project && role) {
            return (
              <HirerViewRatingCard
                key={rating.ratingId}
                rating={rating}
                project={project}
                role={role}
              />
            );
          }

          return (
            <SkeletonHirerViewRatingCard key={`loading-${rating.ratingId}`} />
          );
        })}
      </Div>
    </Div>
  );
};

export default ViewRatingsPage;
